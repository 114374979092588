/* Variabili Tema AZIMUT ================================================ */
$dark: #2E2D2C;
$gray-light-60: #7D7D7D;
$gray-light-50: #C2C6C9;
$gray-light-40: #E9EBE9;
$gray-light-30: #DADADA;
$gray-light-20: #F1F1F1;
$gray-light-10: #F8F8F8;
$gray-sky-blue-20: #C4D5EE;
$dark-blue: #163B73;
$eco: #00B482;

/* Variabili Generali =================================================== */
$font-primary: Helvetiva-Neue, sans-serif;
$font-color: $dark;
$bg-color: $gray-light-10;
$bg-dialog: $gray-light-10;
$bg-banner: $gray-sky-blue-20;
$shadow: 0 2px 4px rgba(0,0,0,0.1);
$shadow-hover: 0 2px 4px rgba(0,180,130,0.2);
$shadow-error: 0 2px 4px rgba(229,48,48,0.2);
$border-radius: 12px;
$green-success: #13c55a;
$warning: #F6D61C;
$tertiary: #F25A50;