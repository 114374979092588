/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "./styles/fonts";
@import "./styles/variables";
@import "./styles/toastr";
@import "./styles/theme";
@import "./styles/other-screen";
@import "./styles/tablet";
@import "./styles/mobile";

/* Formatter =================================================== */
body {
  margin: 0;
  padding: 0;
  color: $font-color;
  background: $bg-color;
  font-family: $font-primary;
  font-size: 0.875rem;
  font-weight: 300;
  text-decoration: none;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

a:focus,
a:active,
button:focus,
button:active {
  outline: 0;
}

button:hover,
button:focus {
  outline: 0;
}

.clear {
  clear: both;
}

h1 {
  font-weight: 600;
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 600;
}

h4 {
  font-weight: 600;
}

h5 {
  font-weight: 600;
}

h6 {
  font-weight: 600;
}

p {
  font-size: 16px;
  margin-bottom: 0.5rem;
}

b {
  font-weight: 600;
}

.fieldset {
  margin-bottom: 1.5rem;

  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.313rem;
  }

  input,
  .p-input-icon-left,
  .p-input-icon-right {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
  }
}

// ScrollBar ===================================================

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:horizontal {
  border-top: 0;
  border-bottom: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
}

// Spinner ===================================================
.loading-overlay {
  position: fixed;
  z-index: 10001;
  background: rgba(249, 249, 249, 0.25);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .wrap-spinner {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 10005;
    top: 43.5%;

    .lds {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      display: inline-block;
      text-align: center;
      position: relative;
    }

    .lds div {
      display: inline-block;
      position: absolute;
      left: 6px;
      width: 13px;
      background: #192b2f;
      animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds div:nth-child(1) {
      left: 6px;
      animation-delay: -0.24s;
    }

    .lds div:nth-child(2) {
      left: 26px;
      animation-delay: -0.12s;
    }

    .lds div:nth-child(3) {
      left: 45px;
      animation-delay: 0;
    }
  }
}

@keyframes lds {
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

// CARDS =====================================================
.sk-card {
  padding: 1.5rem;
  position: relative;
  background: #fff;
  border-radius: $border-radius;
  box-shadow: 0px 12px 24px rgba(243, 243, 243, 0.5);
  z-index: 0;

  &.no-relative {
    position: initial;
  }

  &.external-page {
    box-shadow: 0px 12px 32px #d4d4d4;
  }

  .ad-card-header {
    margin-bottom: 24px;
  }

  &.extra-bottom-left::after {
    content: "";
    position: absolute;
    background: url("./assets/images/extra-card-bottom-left.svg");
    width: 89px;
    height: 67px;
    z-index: -1;
    bottom: 0;
    left: 0;
  }

  &.extra-bottom-right::before {
    content: "";
    background: url("./assets/images/extra-card-bottom-right.svg");
    width: 68px;
    height: 37px;
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
  }
}

// TEMA PAGINE ESTERNE =====================================================
#main:not(.registation) {
  background-image: url("./assets/images/bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  background-size: cover;
  min-height: 100vh;

  .main-body {
    padding: 40px;
  }
}

// NOT_FOUND =====================================================
.not-found {
  .content {
    text-align: center;
  }
}

// REGISTRATION =====================================================
.registration,
.registration-completed,
.reset-password {
  .content {
    text-align: left;
    max-width: 400px;

    .heading {
      margin-bottom: 35px;

      h2 {
        font-size: 30px;
      }
    }

    .check-privacy {
      margin: 45px 0 25px 0;
    }

    .p-input-icon-right {
      .pi-calendar {
        z-index: 10;
      }
    }

    .p-calendar {
      width: 100%;
    }

    .bt-registration {
      width: 100%;
    }
  }
}

.registration {
  .fieldset {
    .languageDrop {
      min-height: 48px;
    }
  }
}

// REGISTRATION-COMPLETED =====================================================
.registration-completed,
.resetpwd-completed {
  .content {
    text-align: center;

    .check-icon {
      background: url("../src/assets/images/icone-azimut/Done_ring_round-green.svg");
      background-position: center;
      background-size: cover;
      width: 80px;
      height: 80px;
      margin: 0 auto;
      margin-bottom: 20px;
      color: $eco;
    }

    .heading {
      h2, h4 {
        color: $eco;
      }
    }

    .bt-registration {
      width: auto;
    }
  }
}

// APPSTORE =====================================================
.appstore {
  .content {
    .iphone {
      position: relative;
      width: 100%;
      max-width: 500px;
      height: 630px;
      margin: 0 auto;
      margin-bottom: 20px;

      .azimut-app {
        position: absolute;
        z-index: 1;
        left: 15%;
        width: 100%;
        max-width: 350px;
      }

      .circle {
        position: absolute;
        top: 10%;
        z-index: 0;
        width: 500px;
        height: 500px;
        margin: 0 auto;
        background: #ececec7d;
        border-radius: 50%;
      }
    }

    .title {
      text-align: center;
      h2 {
        margin-bottom: 30px;
      }
    }

    .buttons {
      .store {
        img {
          width: 150px;
          margin: 0 15px;
        }
      }
    }
  }
}

// RESET PASSWORD =====================================================
.reset-password {
  .content {
    .heading {
      text-align: center;
    }
  }
}

// RESET PASSWORD COMPLETED =====================================================
.resetpwd-completed {

}

// DELETE PROFILE =====================================================
.delete-profile {

}