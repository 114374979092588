/* Poppins Font =============================================================== */
@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-UltLt.otf') format("truetype");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-UltLtIt.otf') format("truetype");
  font-weight: 100;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-Th.otf') format("truetype");
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-ThIt.otf') format("truetype");
  font-weight: 200;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-Lt.otf') format("truetype");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-LtIt.otf') format("truetype");
  font-weight: 300;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-Roman.otf') format("truetype");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-It.otf') format("truetype");
  font-weight: 400;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-Md.otf') format("truetype");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-MdIt.otf') format("truetype");
  font-weight: 500;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-Bd.otf') format("truetype");
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-BdIt.otf') format("truetype");
  font-weight: 600;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-Hv.otf') format("truetype");
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-HvIt.otf') format("truetype");
  font-weight: 700;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-Blk.otf') format("truetype");
  font-weight: 800;
  font-display: fallback;
}

@font-face {
  font-family: Helvetiva-Neue;
  src: url('../assets/fonts/helvetica-neue/HelveticaNeueLTPro-BlkIt.otf') format("truetype");
  font-weight: 800;
  font-display: fallback;
  font-style: italic;
}
